import React, { useEffect } from 'react'
// import Helmet from 'react-helmet'
import browserLang from 'browser-lang'
import { navigate } from 'gatsby'
import { Props } from './types'
import * as styles from './Layout.module.scss'
import { IntlProvider } from 'react-intl'

export default ({ children, pageContext, path }: Props): JSX.Element => {
  // const { header, footer }: any = globalData ? globalData : {}

  const language = pageContext ? pageContext.language : 'sv'
  const messages = pageContext ? pageContext.messages : {}
  // const seo_data: any = seo ? seo.content : {}
  // const { seo_description, seo_title, seo_image } = getSeo(seo_data)

  useEffect(() => {
    const storageKey = 'is-redirected'
    const isRedirected = localStorage.getItem(storageKey)

    if (!isRedirected) {
      localStorage.setItem(storageKey, 'true')
      const userLanguage = browserLang({
        languages: ['sv', 'en'],
        fallback: 'en',
      })
      if (language !== userLanguage) {
        if (userLanguage === 'en') {
          navigate(`/en${path}`)
        } else {
          if (path === '/en') {
            navigate(path.replace('/en', '/'))
          } else {
            navigate(path.replace('en/', ''))
          }
        }
      }
    }
  }, [])

  return (
    <IntlProvider locale={language} messages={messages}>
      <div>
        {/* {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )} */}
        {/* <SEO
          title={story?.name && story?.slug !== 'home' ? story.name : ''}
          metaDescription={seo_description}
          image={seo_image}
        /> */}

        {children}
      </div>
    </IntlProvider>
  )
}
